@import '../../utilities/mixins/_foundation.scss';
@import '../../utilities/mixins/_home.scss';

// Custom styles generated in JS which will alter layout styles of the main home page in order to make them responsive.
:root {
    --home-profile-height: auto;
    --justify-content-home-profile: none;
    --social-media-a-width: 20%;
    @media (min-width: 475px) and (max-width: $brkpoint-md) {
        --social-media-a-width: 14%;
    }
    --font-size: 1rem;
}

.home {
    @extend %main-container;
    letter-spacing: 2px;
    background-color: $box-bg-color;
    
    &--header {
        border-radius: 5px 5px 0 0;

        h1 {
            @extend %sub-container;
        }
        
        &--nav {
            @extend %sub-container;
            @include nav;
            @include buttons;
            a {
          
                @media (min-width: $brkpoint-md) and (max-width: $brkpoint-lg) and (min-height: 600px) {
                    width: 100%;
                    button {
                    border-radius: 0%;
                    }

                    &:first-of-type button {
                        margin-bottom: 10px;
                    }

                    &:last-of-type button {
                        margin-bottom: 5px;
                    }  
                }
            }
            
            @media (min-width: $brkpoint-md) and (max-width: $brkpoint-lg) and (min-height: 600px) {
                display: block;
            }
        }

        @include mq('medium') {
            @include home-sideBar;         
        }

        hr {
            width: 99%;
            background-color: $text-color;
            margin-top: 12px;
        }

    }

    &--profile {
        @extend %sub-container;
        border-radius: 0 0 5px 5px;
        height: var(--home-profile-height);
        display: flex;
        flex-direction: column;
        justify-content: var(--justify-content-home-profile);
        overflow-y: auto;
        overflow-x: hidden;

        hr {
            @extend %hr;
        }

        p {
            margin-top: 0px;
            margin-bottom: 0px;
        }

        p a[href^='https://openclassrooms.com/'] {
            color: rgb(226, 133, 97);
        }

        p a:hover {
            color: rgb(196, 147, 126);
            transition: color 0.25s ease-out;
        }

        &--socialMedia {
            display: flex;
            justify-content: space-around;

            a {
                width: var(--social-media-a-width);  
                img {
                    width: 100%;
                }
                
                &:hover {
                    @extend %social-media-hover;
                }
            }
        }

        p, ul {
            text-align: start;
        }

        p:last-of-type {
            margin-bottom: 10px;
        }

        h2 {
            text-align: center;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        @include lists;

        @media (max-height: 450px) {
            font-size: 0.9em;
            justify-content: normal;
        }

        @include mq('medium') {
            @include home-sideBar;

            @media (max-width: 1024px) and (max-height: 975px) {
                &--skills, & p:last-of-type, & hr:last-of-type {
                    display: none;
                }
            }
        }

        @include mq('large') {
            justify-content: space-around;

            .home--profile--skills h2 {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            @media (max-height: $brkpoint-md) {
                &--skills, & p:last-of-type, & hr:last-of-type {
                    display: none;
                }
            }
        }
    }

// The section of preview projects on left side of the screen are displayed only in large viewports
    .previewProjects {
        display: none;
        @include mq('medium') {
            @include previewProjects;

            @media (max-height: 450px) {
                font-size: 0.8em;
            }
        }
    }

// An empty div is used at bottom of home--profile section in side bar to determine if extra empty space exists in side bar which needs to be filled with a justify-content value of 'space-evenly'.
    &--profile--empty {
        height: 100%;
    }

// Forces no overflow in medium viewports to ensure a more pleasurable home page experience in devices with viewport width tablet sized or greater.
    @include mq('medium') {
        overflow-y: hidden;
        background-color: transparent;
    }
    
}








