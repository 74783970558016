.about {
    @extend %main-container;
    @include buttons;
    overflow-y: visible;
    background-color: $box-bg-color;

    
    hr:first-of-type {
        @extend %hr;
    }

    p:first-of-type {
        margin-top: 7px;
    }
    
    &--header {
        @extend %sub-container;
        h1 {
            @media (min-width: $brkpoint-md) and (max-width: $brkpoint-lg) {
                margin: 4px 0px 0px;
            }
        }

        &--socialMedia {
            display: none;
        }

        &--socialMedia a:hover {
            @extend %social-media-hover;
        }

        &--nav {
            @include nav;
            @media (min-width: $brkpoint-md) {
                width: 42%;
            }
            button[type='button'] {
                @media (min-width: $brkpoint-md) and (max-width: $brkpoint-lg) {
                    margin-bottom: 0px;
                } 
            }
        }

        @media (min-width: $brkpoint-md) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 12px;
        }

        @media (min-width: $brkpoint-lg) {
            @include socialMediaInHeader(30%, 35%);
        }
    }

    &--main {
        @extend %sub-container;
        text-align: start;
        overflow-y: auto;
    }
}