@import '../../utilities/mixins/effects';
@import '../../utilities/mixins/foundation';

// Variables for holding random values to randomize the vanishing of the initial letters.
:root {
    --top-entry-letter: 0;
    --left-entry-letter: 0;
}

// Styling for div containing the entry letters including the transitions for each letter to fade in and out.
.entryText {
    text-align: center;
    font-size: 2.1em;
    font-family: "Courier New", Courier, monospace;
    line-height: 1.4;
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 10%;
    opacity: 0;

    &__fade-in {
        opacity: 1;
        transition: opacity 4.5s cubic-bezier(.57,.43,0,1.06);
    }

    @include vanishingLetters;

    @include mq('small') {
        font-size: 3.8em;
        @media (max-height: 600px) {
            font-size: 2.5em;
        }
    }

    @include mq('medium') {
        margin-left: 0.5%;
        font-size: 4.75em;
        margin-left: 15%;
        margin-right: 15%;
        @media (max-height: 600px) {
            font-size: 2.8em;
        }
    }

    @include mq('large') {
        font-size: 5.8em;
        margin-top: 0%;
        @media (max-height: 600px) {
            font-size: 4em;
        }
    }

    @include mq('extra-large') {
        font-size: 6.3em;
    }

// Each span is a letter which is styled to have appear 3D with layered shadows.
    span {
        @include introTextShadow;
        margin-left: 1%;
    }
}

