// Sliding in and out animations for each page used with react router's Transition Group.

.translate-appear {
    transform: translateX(0vw);
}

.translate-appear-active {
    transition: transform 0.5s ease-out;
    transform: translateX(90vw);
}

.translate-appear-done {
    transform: translateX(90vw);
}

.translate-enter {
    transform: translateX(0vw);
}

.translate-enter-active {
    transition: transform 0.5s ease-out;
    transform: translateX(90vw);
}

.translate-enter-done {
    transform: translateX(90vw);
}

.translate-exit {
    transform: translateX(90vw);
}

.translate-exit-active {
    transition: transform 0.5s ease-out;
    transform: translateX(180vw);
}

@include mq('large') {
    .translate-appear-active {
        transition: transform 1s ease-out;
        transform: translateX(90vw);
    }

    .translate-enter-active {
        transition: transform 1s ease-out;
        transform: translateX(90vw);
    }

    .translate-exit-active {
        transition: transform 1s ease-out;
        transform: translateX(180vw);
    }
}
