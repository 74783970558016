// Main layout styles for home, portfolio and about pages.
%main-container {
    overflow-x: hidden;
    position: absolute;
    top: 5%;
    left: -80%;
    width: 80%;
    height: 90%;
    letter-spacing: 2px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

// Layout styles for the children divs of the main container divs.
%sub-container {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
}

// Layout styles for the buttons in each project in the portfolio page.
%project-buttons {
    padding: 5px 8px;
    color: $text-color;
    border: 1px solid white;
    margin-bottom: 0px;
    letter-spacing: 2px;
}

// Layout styles for the hr page breaks.
%hr {
    background-color: $text-color;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
}

// Social media on hover styles.
%social-media-hover {
    cursor: pointer;
    transition: transform 0.2s ease-out;
    transform: scale(1.1);
}



