@import '../../utilities/mixins/portfolio';

// Ensure project height is full height as determined by height calculation from JavaScript.
:root {
    --project-height: 0;
}

.portfolio {
    @extend %main-container;
    @include buttons;
    background-color: $box-bg-color;

    hr {
        @extend %hr;
    }

    .projects {
        overflow-y: visible;
    }

    &--header {
        @extend %sub-container;
        h1 {
            @media (min-width: $brkpoint-md) and (max-width: $brkpoint-lg) {
                text-align: start;
                width: 55%;
                margin: 4px 0px 0px;
            }
        }

        &--socialMedia a:hover {
            @extend %social-media-hover;
        }

        &--socialMedia {
            display: none;
        }

        &--nav {
            @include nav;
            @media (min-width: $brkpoint-md) {
                width: 42%;
            }
            button[type='button'] {
                @media (min-width: $brkpoint-md) and (max-width: $brkpoint-lg) {
                    margin-bottom: 0px;
                } 
            }
        }

        @media (min-width: $brkpoint-md) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 12px;
        }

        @media (min-width: $brkpoint-lg) {
            @include socialMediaInHeader(30%, 25%);
        }
    }

// Styling for main project div containing each project in the portfolio
    & > .projects {
        @extend %sub-container;
        height: var(--project-height);
        overflow-y: auto;
    }

// Styling for each project
    &--project {
        @include project;
    }
}
